/* You can add global styles to this file, and also import other style files */

@import "~@clr/ui/clr-ui.min.css";

.plus-icon {
  width: 100% !important;
  height: 100% !important;
  padding: 30%;
  color: #1c8adb;
}

.center {
  text-align: center;
}

.green-icon {
  color: #48960C
}

/*encadrement de logo*/
.img {
  width: 200px;
  margin-top: 20px;
}

.img2 {
  width: 50px;
  margin-top: 10px
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

.no-margin-top {
  margin-top: 2px;
}

.flex {
  display: inline-flex;
}

.flex-block {
  display: inline-block;
}

.cursor-pointer {
  cursor: pointer
}

.resetDate {
  margin: 0 10px 0 10px;
}

.datepicker {
  z-index: 10000 !important;
}

:host ::ng-deep .clr-accordion-header {
  box-shadow: inset 0.3rem 0 0 #5aa220 !important;
}

.right {
  padding-left: 0px;
}

.margin-right-10 {
  margin-right: 10px;
}

.left {
  margin-left: 15px;
}

.overflow-x {
  overflow-x: auto
}

.right-space {
  padding-right: 4px;
}

.bold {
  font-weight: bold !important;
}

.font-size {
  font-size: x-large
}

.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.spinner-pos {
  margin-left: 300px;
}

.color-bleu {
  color: #247bae;
}

/* .tab-color{
  background-color: #e3f5fc !important;
} */